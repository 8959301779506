
import {computed, defineComponent, onMounted, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "CustomerInbox",
  components: {DateTimeFormat},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Customer Messages', [
        {link: true, router: '/customer/inbox', text: 'Messages'},
      ])
    })
    const objectFilter = ref({direction: 'IN', status: "UNREAD"})
    const page = computed(() => store.state.CustomerInboxModule.page);

    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_CUSTOMER_INBOX, objectFilter.value, ['customer']),
    }
  }
})
